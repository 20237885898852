
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';

    import {formHelper} from '@/helpers';

    import {
        SnackbarModule,
    } from '@/store/modules';

    @Component<ViewReset>({})
    export default class ViewReset extends Vue {
        public email: string = '';
        public password: string = '';
        public showpassword: boolean = false;

        public token: string|null = null;

        public tokenExpired: boolean = false;

        public updateFormValid = false;
        public askResetFormValid = false;

        public resetLinkSent = false;
        public passwordUpdatedDialog = false;

        public isSubmittingAskReset = false;
        public isSubmittingUpdate = false;

        public emailRules = formHelper.getEmailsRules();

        public passwordRules = [
            (v: any) => (!v || /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*().,;:\/?^"'\-+])[A-Za-z\d!@#$%^&*().,;:\/?^"'\-+]{12,}$/.test(v)) || `Le mot de passe doit contenir au moins 12 caractères, au moins un chiffre et un caractère spécial !@#$%^&*().,;:?^"+' minimum.`
        ];

        public submitAskReset() {
            const snackbarModule = getModule(SnackbarModule, this.$store);

            this.isSubmittingAskReset = true;

            this.$api.account
                .sendResetPasswordLink(this.email)
                .then(() => this.resetLinkSent = true)
                .catch((error) => {
                    snackbarModule.displayError(
                        `Cette adresse email ne correspond à aucun compte.<br>
                        Si vous avez créé votre compte Vetolib avant le 25 août 2020,
                        veuillez en créer un nouveau afin de profiter pleinement de votre nouveau site !`,
                    );
                })
                .finally(() => this.isSubmittingAskReset = false)
            ;
        }

        public submitUpdatePassword() {
            this.isSubmittingUpdate = true;

            this.$api.account
                .updatePassword({token: this.token as string, password: this.password})
                .then(() => this.passwordUpdatedDialog = true)
                .catch((err) => {
                    if (err.response.status === 406) {
                        this.tokenExpired = true;
                        this.token = null;
                    }
                })
                .finally(() => this.isSubmittingUpdate = false)
            ;
        }

        private mounted() {
            if (this.$route.query.token) {
                this.token = this.$route.query.token as string;
            }
        }
    }
